import React, { useContext } from 'react';
import { LanguageContext } from '../LanguageContext'; // Adjust the path as needed
import Slideshow from './Slideshow';
import '../Services.css';

import housemaster1 from '../assets/hausemaster.jpg'; 
import housemaster2 from '../assets/hausemaster2.webp'; 
import housemaster3 from '../assets/housemaster3.jpeg'; 
import housemaster4 from '../assets/housemaster4.jpg'; 
import housemaster5 from '../assets/housemaster5.jpg'; 
import housemaster6 from '../assets/housemaster6.jpg'; 
import housemaster7 from '../assets/housemaster7.jpg'; 
import allkinds from '../assets/allkinds.jpg'; 
import allkinds2 from '../assets/allkinds2.jpg'; 
import allkinds3 from '../assets/allkinds3.jpg'; 
import buro1 from '../assets/buro.jpeg'; 
import buro2 from '../assets/buro2.jpg'; 
import buro3 from '../assets/buro3.webp'; 
import buro4 from '../assets/buro4.jpg'; 
import stairs1 from '../assets/stairs.jpg'; 
import stairs2 from '../assets/stairs2.jpg'; 
import stairs3 from '../assets/stairs3.webp'; 
import stairs4 from '../assets/stairs4.jpg'; 
import stairs5 from '../assets/stairs5.jpg'; 

const housemaster = [
housemaster1,
housemaster2,
housemaster3,
housemaster4,
housemaster5,
housemaster6,
housemaster7
];
const allkind = [
  allkinds,allkinds2,allkinds3
];

const buro = [
  buro1,
buro2,
buro3,
buro4
];

const stairs = [
  stairs1,
stairs2,
stairs3,
stairs4,
stairs5
];


function Services() {
  const { t } = useContext(LanguageContext); // Use the context to get the translation function

  return (
    <section id="services">
    <h2>{t.services.ourservices}</h2>
    <div className="service-row">
      <div className="service-description">
        <h3>{t.services.firstService}</h3>
        <p>{t.services.firstDescription}</p>
      </div>
      <div className="service-slideshow">
        <Slideshow images={housemaster} width="400px" />
      </div>
    </div>
    <div className="service-row ">
      <div className="service-slideshow">
        <Slideshow images={allkind} width="400px" />
      </div>
      <div className="service-description">
      <h3>{t.services.secondService}</h3>
        <p>{t.services.secondDescription}</p>
      </div>
    </div>
    <div className="service-row">
      <div className="service-description">
        <h3>{t.services.thirdService}</h3>
        <p>{t.services.thirdDescription}</p>
      </div>
      <div className="service-slideshow">
        <Slideshow images={buro} width="400px" />
      </div>
    </div>
    <div className="service-row ">
      <div className="service-slideshow">
        <Slideshow images={stairs} width="400px" />
      </div>
      <div className="service-description">
      <h3>{t.services.fourthService}</h3>
        <p>{t.services.fourthDescription}</p>
      </div>
    </div>
    {/* Repeat the pattern for other services */}
  </section>
  );
}

export default Services;
