import React, { useContext } from 'react';
import { LanguageContext } from '../LanguageContext'; // Adjust the path as needed
import Slideshow from './Slideshow';
import cleanHouseImage from '../assets/school-cleaning-2.jpeg';
import cleanHouseImage2 from '../assets/house-cleaning.jpg';
import cleanHouseImage3 from '../assets/restaurant-cleaning.jpeg';
import cleanHouseImage4 from '../assets/office-cleaning-2.jpeg';
import cleanHouseImage5 from '../assets/school-cleaning.jpg';
import cleanHouseImage6 from '../assets/cleaning-office.png';


const imageUrls = [
  cleanHouseImage,
  cleanHouseImage2,
  cleanHouseImage3,
  cleanHouseImage4,
  cleanHouseImage5,
  cleanHouseImage6,
  // ...more images
];



function About() {
  const { t } = useContext(LanguageContext); // Use the context to get the translation function
  
  return (
    <section id="about">
      <Slideshow images={imageUrls} width="750px" />
      <h2 style={{paddingTop:'20px'}}>{t.about.aboutus}</h2> {/* Translated text */}
      <p>{t.about.description}</p> {/* Translated text */}
      <h2 style={{paddingTop:'20px'}}>{t.about.meetteam}</h2> {/* Translated text */}
      <div className="team-container">
        <div className="team-member">
          <img src={require('../assets/bebo2.jpg')} alt="Silyan Haydarliev" className="team-photo" />
          <h3>Silyan Haydarliev</h3>
          <p>{t.about.owner}</p> {/* Translated text */}
        </div>
        <div className="team-member">
          <img src={require('../assets/rosen.jpg')} alt="Rosen Rosenov" className="team-photo" />
          <h3>Rosen Rosenov</h3>
          <p>{t.about.manager}</p> {/* Translated text */}
        </div>
      </div>
    </section>
  );
}

export default About;
