import React from 'react';
import About from './components/About';
import Header from './components/Header';
import Services from './components/Services';
import Contact from './components/Contact';
import './styles.css';
import { LanguageProvider } from './LanguageContext';

function App() {
  return (
    <LanguageProvider>
    <div className="App">
      <Header />
      <main style={{ paddingTop: `${280}px` }}>
        <About />
        <Services />
        <Contact />
      </main>
    </div>
  </LanguageProvider>
  );
}

export default App;
