import React, { useContext } from 'react';
import { LanguageContext } from '../LanguageContext'; // Adjust the path as needed
import '../Contact.css';

import locationIcon from '../assets/location.png'; 
import phoneIcon from '../assets/phone.png'; 
import mailIcon from '../assets/mail.png'; 



function Contact() {
  const { t } = useContext(LanguageContext); // Use the context to get the translation function

  return (
    <section id="contact">
    <h2>{t.contact.contactus}</h2> {/* Translated text */}
    <p>{t.contact.introduction}</p> {/* Translated and welcoming introduction */}
    <div className="contact-details">
      <div>
        <img src={locationIcon} alt="Location"  />
        <p>Sonnenallee 203, 12059 Berlin</p> {/* Replace with actual address */}
      </div>
      <div>
        <img src={phoneIcon} alt="Phone"  />
        <p>+49 176 61653371 / +49 163 4750577</p> {/* Replace with actual phone number */}
      </div>
      <div>
        <img src={mailIcon} alt="Mail"  />
        <p>info@gebaeudereinigung-haydarlirliev.eu</p> {/* Replace with actual email */}
      </div>
      </div>
    <hr align="left" className="contact-divider" /> {/* Horizontal line */}
    <form>
      <input type="text" placeholder={t.contact.name} /> {/* Translated placeholder */}
      <input type="email" placeholder={t.contact.email} /> {/* Translated placeholder */}
      <textarea placeholder={t.contact.message}></textarea> {/* Translated placeholder */}
      <button type="submit">{t.contact.send}</button> {/* Translated button text */}
    </form>
  </section>
  );
}

export default Contact;
