import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-scroll';
import '../Header.css';
import { LanguageContext } from '../LanguageContext';
// Import flag images
import germanyFlag from '../assets/germany.png'; // Adjust the path to your actual flag image
import britainFlag from '../assets/united-kingdom.png';

const Header = () => {
  const [isSticky, setIsSticky] = useState(false);
  const { currentLang, toggleLanguage, t } = useContext(LanguageContext);

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 200);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  

  return (
    <header className={`header ${isSticky ? 'sticky' : ''}`}>
    <div className="top-row">
    <div className="phone-info">
      <span>{t.header.question}</span>
      <span className="phone-number">(+49) 176 61653371</span>
      <span className="phone-number">(+49) 163 4750577 </span>
    </div>
    <div className="logo-container">
      <img src={require('../assets/Resmi-full-logo-2.png')} alt="Resmi Logo" className={`logo ${isSticky ? 'small' : ''}`} />
    </div>
    <div className="language-contact-container">
      <button onClick={toggleLanguage} className="language-switch">
        <img src={currentLang === 'en' ? germanyFlag : britainFlag} alt={currentLang === 'en' ? 'German' : 'English'} className="flag-icon" />
      </button>
      <Link to="contact" className="contact-button" smooth={true} duration={1000}>{t.header.contactus}</Link>
    </div>
  </div>
  <nav className="nav">
  <Link to="about" className="nav-link" smooth={true} duration={1000}>{t.header.about}</Link>
  <Link to="services" className="nav-link" smooth={true} duration={1000}>{t.header.services}</Link>
  <Link to="contact" className="nav-link" smooth={true} duration={1000}>{t.header.contact}</Link>
</nav>
  </header>
  );
};

export default Header;
