import React, { useState, useEffect, useRef } from 'react';
import '../Slideshow.css';

const Slideshow = ({ images, width }) => {
  const [current, setCurrent] = useState(0);
  const length = images.length;
  const timeoutRef = useRef(null);

  const delay = 3000; // Delay in milliseconds

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setCurrent((prevIndex) =>
          prevIndex === length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [current, length]);

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  // Handlers for manual navigation
  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
    resetTimeout();
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
    resetTimeout();
  };

  // Handler for navigation dots
  const dotNavigation = (index) => {
    setCurrent(index);
    resetTimeout();
  };

  if (!Array.isArray(images) || images.length <= 0) {
    return null;
  }

  return (
    <div className="slideshow" style={{ width: width }}>
      {images.map((image, index) => (
        <div className={index === current ? 'slide active' : 'slide'} key={index}>
          {index === current && (
            <img src={image} alt={`Clean environment ${index}`} className="image" />
          )}
        </div>
      ))}
      <div className="dots">
        {images.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === current ? 'active' : ''}`}
            onClick={() => dotNavigation(index)}
          ></span>
        ))}
      </div>
      <span className="left-arrow" onClick={prevSlide}>&#10094;</span>
      <span className="right-arrow" onClick={nextSlide}>&#10095;</span>
    </div>
  );
};

export default Slideshow;
