import React, { useState, createContext } from 'react';
import translations from './translations';

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [isLanguageEnglish, setIsLanguageEnglish] = useState(true);
  
  const toggleLanguage = () => {
    setIsLanguageEnglish(!isLanguageEnglish);
  };

  const currentLang = isLanguageEnglish ? 'en' : 'de';

  return (
    <LanguageContext.Provider value={{ currentLang, toggleLanguage, t: translations[currentLang] }}>
      {children}
    </LanguageContext.Provider>
  );
};